@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hide-scrollbar::-webkit-scrollbar {
     display: none;
  }

  .forbid-select-bg {
    -webkit-tap-highlight-color: transparent;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
}

body {
  /* 默认字体颜色 */
  color: #1B1F4D;
  background: #fff;
  max-width: 1920px;
  margin: 0 auto;
}

/* 清除移动端选中元素的默认蓝色边框 */
a:focus {
  outline: none; /* 清除默认的蓝色边框 */
}
button:focus {
  outline: none; /* 清除默认的蓝色边框 */
}

input {
  border: none;
  background-color: transparent;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

/* 关闭浏览器默认回填的样式 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition:
    color 11111s ease-out,
    background-color 111111s ease-out;
}

/* 去除search框清除按钮 */
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

/* 禁止图片被拖拽 */
img{
  -webkit-user-drag: none;
  user-select: none;
}

/* 解决ios14一下 父级元素使用z-index 后 元素的滚动被禁止 */
 .fixScroll{
  -webkit-transform: translate3d(0,0,0);
 }

/* 自定义 全局 基础样式 */
@layer utilities {
  .translate3d0 {
    transform: translate3d(0, 0, 0);
  }
  .linearGradient1 {
    background: radial-gradient(circle, #009dff 11.46%, #6d38fb 51.04%, #ff5353 88.24%);
    background-size: 210% 150%;
    animation: gradient-animation 8s ease infinite;
    background-clip: text;
    color: transparent;
    /* 只在pc不允许换行 移动端默认允许换行 */
    @media (min-width: 1024px){
      white-space: nowrap;
    }
  }
  .linearGradientForMath {
    background: radial-gradient(circle, #256AF2 1.67%, #14A9FF 98.38%);
    background-size: 210% 150%;
    animation: gradient-animation 8s ease infinite;
    background-clip: text;
    color: transparent;
    /* 只在pc不允许换行 移动端默认允许换行 */
    @media (min-width: 1024px){
      white-space: nowrap;
    }
  }
  /* TODO 如何写字组件里面 只用了一次 */
  .linearGradient2 {
    background: radial-gradient(65.77% 101.32% at 71.18% 48.2%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(90deg, rgba(255, 83, 83, 0.08) -5%, rgba(251, 138, 56, 0.08) 60.27%, rgba(66, 0, 255, 0.08) 99.63%), #FFF;
  }
  .linearGradient3 {
    background: radial-gradient(65.77% 101.32% at 71.18% 48.2%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(90deg, rgba(18, 141, 255, 0.08) -5%, rgba(0, 180, 205, 0.08) 99.63%), #FFF;
  }

  .linearGradientEnglishTeahcerCardBg {
    background: radial-gradient(65.77% 101.32% at 71.18% 48.2%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(90deg, rgba(159, 83, 255, 0.08) -5%, rgba(66, 0, 255, 0.08) 99.63%), #FFF;
  }

  .english-dashed-hover-text:hover span {
    color: white;
  }

  /* 中文渐变 */
  .linearGradientChinese {
    background: radial-gradient(circle, #FF1D43 0.06%, #FF3A3A 45.52%, #FF765A 91.03%);
    background-size: 210% 150%;
    animation: gradient-animation 8s ease infinite;
    background-clip: text;
    color: transparent;
    /* 只在pc不允许换行 移动端默认允许换行 */
    @media (min-width: 1024px){
      white-space: nowrap;
    }
  }
  /* 英文渐变 */
  .linearGradientForEnglish {
    background: radial-gradient(circle, #8642F8 35.47%, #C740E8 98.37%);
    background-size: 210% 150%;
    animation: gradient-animation 8s ease infinite;
    background-clip: text;
    color: transparent;
    /* 只在pc不允许换行 移动端默认允许换行 */
    @media (min-width: 1024px){
      white-space: nowrap;
    }
  }
  /* 视觉隐藏类 */
  .visuallyHidden {
    height: 1px;
    overflow: hidden;
    width: 1px;
    /* Keep the layout */
    position: absolute;
    /* Remove any visible trace (e.g. background color) */
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%); /* browsers in the future */
    /* Prevent the screen reader to skip spaces between words */
    white-space: nowrap;
  }
  /* 隐藏滚动条 */
  .hideScrollbar::-webkit-scrollbar {
    display: none; /* 完全隐藏滚动条 */
  }

  .hideScrollbar {
    -ms-overflow-style: none; /* 对IE和Edge的兼容 */
    scrollbar-width: none; /* 在Firefox中隐藏滚动条 */
  }

  .linearGradient4 {
    background: linear-gradient(90deg, #FF5353 -36.95%, #6D38FB 47.05%, #009DFF 99.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

/* 组件 */
@layer components {
  /* 少用 自定义class的做法会生成更多的样式代码，造成生成的css文件变得更大。 */
  /* 旧：模块容器 最宽1440 居中 两边边距  */

  /* 新：

  移动端  375 - 768 边距20px 拉伸
  pad  768 - 1080  边距20px？ 用移动端样式
  pc端
      1024 - 1440 版芯984最宽 居中 边距20px
  pc端
      1440 - 1900 版芯1440 居中 边距20px
  */
  .container {
    /* @apply px-5 md:mx-auto md:max-w-[1464px] md:px-8; */
    @apply px-5  md:mx-auto lg:max-w-screen-lg xl:max-w-screen-xl mx-auto;
  }

  /* 模块边距 距离上面 （间距ui其实不统一）
  1920 1440 375
  200px  160px 80px
  */
  .marginModelTop{
    @apply mt-20 lg:mt-40 xl:mt-50;
  }
  /* 模块边距 距离上面 （间距ui其实不统一 有彩色按钮的情况）
  1920 1440 375
  200px  160px 96px
  */
  .marginModelTop2{
    @apply mt-24 lg:mt-40 xl:mt-50;
  }
  /* 大标题 距离下面
  1920 1440 375
  60px 48px 24px
  */
  .marginTitleBottom{
    @apply mb-6 lg:mb-12 xl:mb-15;
  }

  /* 部分模块最宽宽度 居中
   1920 1440 375
   1164px  850px full
   */
   .maxModuleWidth{
    @apply mx-auto  lg:max-w-[850px] xl:max-w-[1164px] ;
  }

    /* 副标题规范 */
  /* pc 1920 1440 内容区域的70%
     移动端由container限定 20px
   */
   .subTitleMaxWidth {
    @apply lg:max-w-[70%] lg:mx-auto
  }
  .bgGradientColorful {
    @apply bg-[linear-gradient(90deg,rgb(196,63,252)_3.42%,rgb(253,42,131)_23.028%,rgb(248,119,55)_40%,rgba(255,83,83,1)_54.275%,rgba(109,56,251,1)_80.57%,rgba(0,157,255,_1)_100%)];
  }

}
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  10% {
    background-position: -10% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  90% {
    background-position: -10% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* TODO 改为 tailwindcss */
.colorfulBtn:hover {
  animation: gradient-animation-2 10s ease infinite;
}

/* TODO 改为 tailwindcss */
.colorfulBtn:hover::after{
  animation: gradient-animation-2 10s ease infinite;
}
@keyframes gradient-animation-2 {
  0% {
    /* 最右边 */
    background-position: 100% 0%;
    background-size: 220% 100%;
  }
  20% {
    /* 最左边 */
    background-position: 0% 0%;
  }

  30% {
    /* 最左边 往右边 */
    background-position: 30% 0%;
    background-size: 500% 100%;
  }
  60% {
    /* 最左边 往右边 */
    background-position: 70% 0%;
    background-size: 600% 100%;
  }
  70% {
    /* 最左边 往右边 */
    background-position: 90% 0%;
    background-size: 600% 100%;
  }

  70% {
/* 最左边 往右边 */
background-position: 90% 0%;
background-size: 800% 100%;
  }

  100% {
    /* 最右边 */
    background-position: 100% 0%;
    background-size: 800% 100%;
  }
}
.rc-dialog-wrap {
  z-index: 9999999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-dialog-mask {
  z-index: 9999999999 !important;
}

.rc-drawer {
  z-index: 9999999999 !important;
}

.rc-drawer-content-wrapper {
  top: auto !important;
  right: auto !important;
  bottom: 0 !important;
  box-sizing: border-box !important;
  overflow: visible !important;
  width: 100vw !important;
  overflow-x: hidden;
}

/* 用于特殊布局！！劲量不使用 ！！  用于处理部分文案  影响后期品牌自定义文案配置 目前是ui确定固定换行位置 如果强制换行 其实在手机换行 那么在pad就也是换行 pad能显示下 为什么换行 所有文案自动换行   */
/* 未使用 @apply 使用在dangerouslySetInnerHTML 不生效 */
.hide-pc{
  @media (min-width: 1024px) {
    display: none;
  }
}

.hide-mobie{
  @media (max-width: 1023px) {
    display: none;
  }
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  transform: translate(0, 9px) !important;
  margin-left: -10px !important;
  border-width: 10px 10px 0 !important;
  border-top-color: #fff !important;
  padding: 0 !important;
}

.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 0 !important;
  margin-top: -10px !important;
  border-width: 10px 10px 10px 0 !important;
  border-right-color: #fff !important;
  transform: translateX(calc(-100% + 1px)) !important;
  padding: 0 !important;
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 0 !important;
  margin-top: -10px !important;
  border-width: 10px 0 10px 10px !important;
  border-left-color: #fff !important;
  transform: translateX(calc(100% - 1px)) !important;
  padding: 0 !important;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  transform: translate(0, -9px) !important;
  margin-left: -10px !important;
  border-width: 0 10px 10px !important;
  border-bottom-color: #fff !important;
  padding: 0 !important;
}
