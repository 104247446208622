.theme-color-text-MATH {
  color: #443de5;
}
.theme-color-text-ENGLISH {
  color: #6700ea;
}
.theme-color-text-CHINESE {
  color: #cd292a;
}

.theme-color-bg-MATH {
  background: #443de5;
}
.theme-color-bg-ENGLISH {
  background: #6700ea;
}
.theme-color-bg-CHINESE {
  background: #cd292a;
}

.theme-color-border-MATH {
  border: 1px solid #443de5;
}
.theme-color-border-ENGLISH {
  border: 1px solid #6700ea;
}
.theme-color-border-CHINESE {
  border: 1px solid #cd292a;
}

.theme-color-strong-MATH {
  &:nth-child(1) strong {
    background-color: #443de5;
  }
}
.theme-color-strong-ENGLISH {
  &:nth-child(1) strong {
    background-color: #6700ea;
  }
}
.theme-color-strong-CHINESE {
  &:nth-child(1) strong {
    background-color: #cd292a;
  }
}

.theme-color-i-MATH {
  &:nth-child(1) i {
    background-color: #443de5;
  }
}
.theme-color-i-ENGLISH {
  &:nth-child(1) i {
    background-color: #6700ea;
  }
}
.theme-color-i-CHINESE {
  &:nth-child(1) i {
    background-color: #cd292a;
  }
}

.theme-color-light-bg-MATH {
  background: #f0f8fe;
}
.theme-color-light-bg-ENGLISH {
  background: #f0f8fe;
}
.theme-color-light-bg-CHINESE {
  background: #fff5f3;
}

.theme-color-hover-MATH {
  &:hover {
    color: #443de5;
  }
}
.theme-color-hover-ENGLISH {
  &:hover {
    color: #6700ea;
  }
}
.theme-color-hover-CHINESE {
  &:hover {
    color: #cd292a;
  }
}
