.toast {
  position: fixed;
  z-index: 10000;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &-container {
    z-index: 999;
    display: flex;
    overflow: hidden;
    align-items: center;
    // transform: translate(-50%, 0);
    // height: 42px;

    width: auto;
    max-width: 80%;
    padding: 10px 12px;

    font-size: 13px;
    line-height: 20px;

    transition: all .4s;
    text-overflow: ellipsis;

    color: #fff;
    color: #1b1f4d;
    border-radius: 6px;

    &-primary {
      border: 1px solid #d9d9d9;
    }

    &-success {
      // border: 1px solid #b7eb8f;
      background: rgba(229, 246, 221, .9);
    }

    &-warning {
      // border: 1px solid #ffe58f;
      background: rgba(252, 243, 232, .9);
    }

    &-error {
      // border: 1px solid #ff5353;
      background: rgba(253, 236, 237, .9);
    }
  }

  &-enter {
    display: flex;
  }

  &-exit {
    animation: toast-out .3s ease-out forwards;
  }

  &-enter-active {
    display: flex;

    animation: toast-in .6s ease-out forwards;
  }

  &-enter-done {
    display: flex;

    transform: scale(1);

    opacity: 1;
  }
}

.toast-wrapper {
  flex: 1;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  transition: opacity 0.9s ease-in-out;
  text-align: center;

  opacity: 0;
}

.show-content {
  opacity: 1;
}

.toast-icon {
  display: flex;
  align-items: center;

  width: 14px;
  height: 14px;
  margin-right: 8px;
}

@keyframes toast-in {
  from {
    transform: scale(.3);

    opacity: 1;
  }

  30% {
    transform: scale(1.08);

    opacity: 1;
  }

  60% {
    transform: scale(.99);

    opacity: 1;
  }

  to {
    transform: scale(1);

    opacity: 1;
  }
}

@keyframes toast-out {
  from {
    transform: scale(1);

    opacity: 1;
  }

  to {
    transform: scale(.8);

    opacity: 0;
  }
}
