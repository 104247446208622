@import "~@/styles/mediaQuery";
@import '~@/styles/viewport';

.toast {
  position: fixed;
  z-index: 6000666666666;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: vm(11) vm(18);
  pointer-events: none;
  display: flex;
  align-items: center;
  line-height: 1.4;
  backdrop-filter: blur(10px);
  padding: 14px 20px;
  border-radius: 8px;
  font-size: 16px;
  max-width: 243px;
  @include mediaXL {
    left: 50%;
    top: 50%;
    font-size: 22px;
  }
  @include mediaS {
    left: 50%;
    top: 60%;
    font-size: 16px;
    max-width: 100%;
    text-align: center;
    margin: auto;
    padding: 14px 20px;
  }
}

.success {
  color: #00ac5b;
}

.icon {
  margin-right: vm(8);
  svg,
  img {
    vertical-align: middle;
  }
}
